<template>
	<div class="card flights">
		<div class="card-section" @click="showFlights = !showFlights">
			<div class="all-flown" v-if="allFlightsFlown">
				All flights completed
			</div>
			<div class="top">
				{{ name }}
				<div class="carbon" v-if="co2">
					<img class="icon" width="30px" src="@/assets/carbon/co2-icon.png" />
					{{ co2 }}KG
				</div>
			</div>
			<div class="flight-opt-row">
				<div class="flight-details">
					<div class="flights-count">
						<div class="count">{{ flightsData.length }}</div>
						<img class="flight" alt="Flight Icon" width="14px" src="@/assets/trip/flights.png" />
					</div>
					<div class="detail start">
						<img class="flight-icon" alt="Take-off Icon" width="21px" src="@/assets/flights-details/flight-takeoff.png" />
						<div class="time">{{ formatTime(departureDisplay) }}</div>
						<div class="airport">{{ origin }}</div>
					</div>

					<div class="journey">
						<div class="journey-length">
							<span v-if="travelTime">{{ formatTimeRange(travelTime) }}</span>
						</div>
						<div class="line">
							<div class="small-line"></div>
							<div class="ball start"></div>
							<div class="layover-balls">
								<div v-for="layover in layoversTotal" :key="layover" class="ball middle"></div>
							</div>
							<div class="ball end"></div>
						</div>
						<div class="layovers">
							<div v-if="layoversTotal > 1">
								{{layoversTotal}} layovers
							</div>
							<div v-else-if="layoversTotal == 0"> No layovers</div>
							<div v-else> {{ layoversTotal }} layover </div>
						</div>
					</div>
					
					<div class="detail end">
						<img class="flight-icon" alt="Landing Icon" width="21px" src="@/assets/flights-details/flight-land.png" />
						<div class="time">
							{{ formatTime(arrivalDisplay) }}
						</div>
						<div class="airport">
							{{ destination }}
						</div>
					</div>
				</div>
			</div>
			
			<div class="bottom">
				<div class="section expand">
					<span v-if="showFlights">Hide all details</span>
					<span v-else>Show all details</span>
					<img class="arrow" src="@/assets/trip/arrow-up-dark.png" :class="{ selected: showFlights }"/>
				</div>
			</div>
		</div>
		<div v-show="showFlights">
			<div v-for="(flight, index) in flightsData" :key="index">
				<div class="card-section flight dotted">
					<!-- {{ flight }} -->
					<div class="top">
						<div>
							<div class="flight-number">Flight {{ index + 1 }}</div>
							<div class="flown" v-if="flight.isFlown">Past Departure</div>
						</div>
						<div class="details">
							<span class="item" v-if="flight.terminal && !flight.isFlown"><strong>Terminal:</strong> {{ flight.terminal }}</span>
						</div>
					</div>
					<div class="top">
						<span v-if="flight.originData">{{ flight.originData.name }} > {{ flight.destinationData.name }}</span>
						<span v-else>{{ flight.origin }} > {{ flight.destination }}</span>
						<div class="carbon" v-if="flight.co2">
							<img class="icon" width="16px" src="@/assets/carbon/co2-icon.png" />
							{{ flight.co2.amount }}{{ flight.co2.unit }}
						</div>
					</div>
					<div class="flight-opt-row">
						<div class="flight-details">
							<div class="logo-container">
								<img class="airline-logo" alt="Airline Logo" width="36px" :src="getCarrierLogoPath(flight.carrier.code)" @error="setDefaultCarrierLogo" />
								<div class="carrier-code" v-text="flight.carrier.code"></div>
							</div>
							<div class="detail start">
								<div class="date">{{ formatDateWithoutYear(flight.departureTime) }}</div>
								<div class="time">{{ formatTime(flight.departureTime) }}</div>
								<div class="airport" v-if="flight.originData">
									{{ flight.originData.code }}
								</div>
								<div class="airport" v-else>
									{{ flight.origin }}
								</div>
							</div>

							<div class="journey">
								<div class="journey-length">
									<span v-if="flight.flightTime">{{ formatTimeRange(flight.flightTime) }}</span>
								</div>
								<div class="line">
									<div class="small-line"></div>
									<div class="ball start"></div>
									<div class="ball end"></div>
								</div>
							</div>
							
							<div class="detail end">
								<div class="date">{{ formatDateWithoutYear(flight.arrivalTime) }}</div>
								<div class="time">
									{{ formatTime(flight.arrivalTime) }}
								</div>
								<div class="airport" v-if="flight.destinationData">
									{{ flight.destinationData.code }}
								</div>
								<div class="airport" v-else>
									{{ flight.destination }}
								</div>
							</div>
						</div>
						<div class="airline-name">
							{{flight.carrier.name}}
							<div class="flight-number">
								{{ flight.carrier.code}} {{flight.flightNumber}}
							</div>
						</div>
					</div>
					
					<div class="fare-details" v-if="booked && flight.fareDetails">
						<span class="item" v-if="flight.terminal"><strong>Terminal:</strong> {{ flight.terminal }}</span>
						<span class="item" v-if="flight.fareDetails.cabin"><strong>Cabin:</strong> {{ flight.fareDetails.cabin.toLowerCase() }}</span>
						<span class="item" v-if="flight.fareDetails.class"><strong>Class:</strong> {{ flight.fareDetails.class }}</span>
						<span class="item" v-if="flight.fareDetails.class">
							<strong>Included Checked Bags: </strong>
							<span v-if="flight.fareDetails.includedCheckedBags.weight && flight.fareDetails.includedCheckedBags.weightUnit">{{ flight.fareDetails.includedCheckedBags.weight }}{{ flight.fareDetails.includedCheckedBags.weightUnit }}</span> 
							<span v-if="flight.fareDetails.includedCheckedBags.quantity && flight.fareDetails.includedCheckedBags.weight && flight.fareDetails.includedCheckedBags.weightUnit"> x</span> 
							<span v-if="flight.fareDetails.includedCheckedBags.quantity">{{ flight.fareDetails.includedCheckedBags.quantity }}</span>
							<img class="baggage-icon" src="@/assets/flights-details/baggage-icon.png" width="15px"/>
						</span>
						<span class="item" v-if="flight.fareDetails.mealServices">
							<strong>Meal Services: </strong> 
							<span v-for="(meal, index) in flight.fareDetails.mealServices" :key="meal.label">
								{{ meal.label }}<span v-if="flight.fareDetails.mealServices.length < index">, </span>
							</span>
						</span>
					</div>
				</div>
				<div class="card-section layover-details dotted" v-if="flightsData[index + 1]" :set="nextFlight = flightsData[index + 1]">
					<div class="container" :set="waitTime = ((new Date(nextFlight.departureTime).getTime() / 1000) - (new Date(flight.arrivalTime).getTime() / 1000)) / 60">
						<div class="property">
							<img src="@/assets/trip/wait-time.png"/>
							{{ formatTimeRange(waitTime) }}
						</div>
						<div class="property" v-if="waitTime > 300">
							<img src="@/assets/trip/long-wait.png"/>
							Long wait
						</div>
						<div class="property" v-if="flight.changeOfPlane">
							<img src="@/assets/trip/plane-change.png"/>
							Plane Change
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import helpers from '@/helpers/helpers.js';

	export default {
		name: 'FlightCard',
		props: {
			name: {
				default: "",
			},
			departure: {
				default: "",
			},
			origin: {
				default: "",
			},
			destination: {
				default: "",
			},
			layoversTotal: {
				default: "",
			},
			layoverLocations: {
				default: "",
			},
			arrivalDisplay: {
				default: "",
			},
			departureDisplay: {
				default: "",
			},
			travelTime: {
				default: "",
			},
			co2: {
				default: false,
			},
			allFlightsFlown: {
				default: false,
			},
			flightsData: {
				default: () => {
					return [];
				},
			},
			booked: {
				default: false,
			},

			loadOpen: {
				default: false, // Defines if the collapsible sections should be open by default
			}
		},
		data() {
			return {
				showFlights: false,				
			}
		},
		computed: {
		
		},
		methods: {
			getImage: (img) => helpers.getImage(img),
			formatTime: (date) => helpers.formatTime(date),
			formatDate: (date) => helpers.formatDate(date),
			formatTimeRange: (timeStamp) => helpers.formatTimeRange(timeStamp),
			formatDateWithoutYear: (date) => helpers.formatDateWithoutYear(date),
			getCarrierLogoPath: (code) => helpers.getCarrierLogoPath(code),
			setDefaultCarrierLogo: (event) => helpers.setDefaultCarrierLogo(event),
		},
		created() {
			if(this.loadOpen) {
				this.showFlights = true;
			}
		}
	}
</script>

<style scoped>
.card {
	position: relative;
	border: 1px solid #C4C4C4;
	border-radius: 16px;
	background: #FCFCFE;

	font-weight: 600;
	font-size: 16px;
	color: black;

	max-width: 370px;
	box-sizing: border-box;
}
.card .card-section {
	padding: 19px 28px;
}
.card .card-section.dotted {
	border-top: 1px dashed #C4C4C4;
}
.card .top {
	margin-bottom: 10px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.card .all-flown {
	background: #118AB2;
	border-radius: 10px;
	display: inline-block;
	padding: 7px 10px;
	padding-left: 35px;
	font-weight: 500;
	font-size: 11px;
	line-height: 13px;
	color: #FFFFFF;
	background-image: url('../../assets/green-tick.png');
	background-repeat: no-repeat;
	background-position: 10px center;
	background-size: 16px;

	margin-bottom: 8px;
}
.card .top .icon {
	display: inline;
	margin-right: 8px;
}
.card .top .carbon {
	display: flex;
	align-items: center;
	margin-left: 10px;
	color: #505050;
}
.card .top .details {
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 15px;
}
.card .top .details strong {
	font-weight: 700;
}
.card .top .flight-number,
.card .flight .flown  {
	display: inline-block;
	font-weight: 700;
	font-size: 11px;
	line-height: 13px;
	color: white;
	background-color: #7F3D9E;
	border-radius: 9px;
	padding: 2px 5px;
	text-transform: uppercase;
	margin-bottom: 3px;
}
.card .top .flight-number {
	margin-right: 10px;
}
.card .flight .flown {
	background: #118AB2;
}
.card .range {
	width: 210px;
	display: flex;
	justify-content: space-between;
	align-items: flex-top;
}
.card .range .start-time,
.card .range .end-time {
	display: inline-block;
	vertical-align: middle;
}
.card .range .date {
	font-weight: 400;
	font-size: 12px;
	color: #50555C;
}
.card .range .start-time {
	padding-right: 10px;
}
.card .range .end-time {
	text-align: right;
	padding-left: 10px;
}
.card .range .location {
	font-size: 12px;
	color: #50555C;
	font-weight: 700;
}
.card .range .journey {
	width: 100px;
	position: relative;
	height: 7px;
	display: inline-block;
	vertical-align: middle;
	margin-top: 7px;
}
.card .range .journey .bar {
	width: 100%;
	height: 1px;
	background-color: #C4C4C4;
	position: relative;
	top: 3px;
}
.card .range .journey .dot {
	border-radius: 4px;
	width: 7px;
	height: 7px;
	background-color: #C4C4C4;
	z-index: 10;
}
.card .range .journey .start.dot {
	position: absolute;
	left: 0;
	top: 0;
}
.card .range .journey .end.dot {
	position: absolute;
	right: 0;
	top: 0;
}

/* Modifications for the specific flights */
.card .flight .range {
	width: 100%;
}
.card .flight .range .journey {
	width: 100%;
	display: flex;
	align-items: center;
}
.card .flight .range .journey .bar {
	top: 0;
}
.card .flight .range .middle {
	width: auto;
	font-weight: 400;
	font-size: 12px;
	color: #50555C;
	padding: 0 5px;
	text-align: center;
	white-space: nowrap;
	max-width: 120px;
}
.card .flight .range .middle .name {
	font-size: 10px;
	max-width: 120px;
    text-align: center;
}

.card .fare-details {
	font-weight: 400;
	font-size: 14px;
	padding-top: 0px;
	color: #50555C;
	margin-top: 15px;
	padding-top: 10px;
	border-top: 1px dashed #C4C4C4;
}
.card .fare-details .item {
	margin-right: 15px;
	line-height: 25px;
	display: inline-block;
	vertical-align: middle;
	text-transform: capitalize;
}
.card .fare-details strong {
	color: black;
}
.card .fare-details .baggage-icon {
	margin-left: 5px;
}

.card .layover-details {
	background-color: #F5FDFF;
	padding: 11px 16px;
}
.card .layover-details .container {
	display: flex;
	flex-wrap: wrap;
}
.card .layover-details .property {
	font-weight: 400;
	font-size: 12px;
	width: 50%;
	margin: 5px 0;
}
.card .layover-details .property img {
	width: 16px;
	vertical-align: middle;
	margin-right: 5px;
}

.card .layover_dots {
	display: flex;
	flex-flow: row wrap;
	justify-content: space-around;
	width: 75px;
	margin: 0 auto;
}
.card .range .journey .layover.dot {
	background-color: black;
	position: absolute;
	top: 0;
}
.card .bottom {
	font-size: 12px;
	font-weight: 400;
	color: #50555C;
	width: 100%;
	position: relative;
	vertical-align: middle;
	text-align: right;
	height: 15px;
	margin-top:5px;
}
.card .bottom .icon {
	vertical-align: middle;
}
.card .bottom .section {
	display: inline-block;
	vertical-align: middle;
}
.card .bottom .section .arrow {
	margin: 0;
	width: 24px;
	float: right;
	transform: rotate(180deg);
}
.card .bottom .section .arrow.selected {
	transform: rotate(0deg);
}
.card .bottom .section.length {
	margin-right: 24px;
}
.card .bottom .section.expand {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	font-weight: 400;
	font-size: 11px;
	line-height: 24px;
	color: #161D27;
}
.card .bottom .section.expand .arrow {
	width: 10px;
	margin-left: 5px;
}
.card .bottom .section.layovers .dot {
	border-radius: 4px;
	width: 7px;
	height: 7px;
	background-color: black;
	display: inline-block;
}

.card .flight-icon {
	display: block;
	margin: 0 auto;
	margin-bottom: 5px;
}
.card .flights-count {
	position: relative;
	width: 24px;
	height: 24px;
	border: 1px solid #E5E5E5;
	border-top-width: 1px;
	border-bottom-width: 1px;
	border-left-width: 1px;
	border-radius: 32px;
	text-align: center;
	display: inline-block;
	background-color: white;
	margin-right: 5px;
}
.card .flights-count img {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
.card .flights-count .count {
	font-weight: 700;
	font-size: 11px;
	line-height: 13px;
	text-align: center;
	color: white;

	background: #7F3D9E;
	width: 14px;
	border-radius: 50%;
	position: absolute;
	right: -5px;
	top: -5px;
	z-index: 10;
}

</style>
