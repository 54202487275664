<template>
	<div class="trip_item" v-if="type != false">
		<div class="bordered status">
			<div class="type-icon">
				<img :src="getTypeImage()" width="52px" />
				<div class="alert-icon">
					<img :src="getAlertImage()" width="24px" />
				</div>
			</div>
			<div class="price">
				<span v-if="price">£{{ price }}</span>
				<button class="button" :class="bookingStatus.code == 'booked' ? bookingStatus.code : alert.alert_code" v-if="showContinueButton" @click="viewTripItem()">Continue</button>
			</div>
		</div>
		<div class="notifications" v-if="alert">
			<div class="notifications-inner" :class="alert.alert_code">
				{{ name }} / <b>{{ alert.main_message }}</b>
			</div>
		</div>
		<div class="notifications">
			<div class="notifications-inner green" v-if="bookingStatus.code == 'booked'">
				{{ name }} / <b>Booked</b>
			</div>
		</div>
		<div class="date">
			{{ formatDate(date) }}
		</div>
		<div v-if="data">
			<div class="bordered travellers" >
				<div class="traveller" v-for="traveller in dynamicTravellers" :key="traveller.traveller_id">
					<div class="main" @click.stop="traveller.visible = !traveller.visible; "> 
						<div class="profile_pic">{{ traveller.data.firstName.charAt(0) }} </div>
						<div class="name">
							{{ traveller.data.firstName }} {{ traveller.data.lastName }}
						</div>
						<img v-if="traveller.collapsable" class="arrow" src="@/assets/trip/arrow-up.png" :class="{ 'selected': traveller.visible }"/>
					</div>
					<div class="info" v-if="traveller.collapsable" v-show="traveller.visible">
						<div class="">DOB: {{ formatDate(traveller.data.dob) }}</div>
						<div class="">Passport: {{ traveller.data.passportNumber }}</div>
						<div class="">Exp. {{ formatDate(traveller.data.passportExpiry) }}</div>
					</div>
				</div>
			</div>
			
			<div v-if="type == 'flight'">
				<FlightCard
					class="flight-card"
					:name="name"
					:origin="data.outbound.origin"
					:destination="data.outbound.destination"
					:layoversTotal="data.outbound.layoversTotal"
					:layoverLocations="data.outbound.layoverLocations"
					:arrivalDisplay="data.outbound.arrivalDisplay"
					:departureDisplay="data.outbound.departureDisplay"
					:travelTime="data.outbound.travelTime"
					:flightsData="data.outbound.flightsArray"
					:booked="bookingStatus.code == 'booked'"
					:co2="data.outbound.totalCo2"
					:allFlightsFlown="data.outbound.allFlightsFlown"

					:loadOpen="loadOpen"
				/>
				<div v-if="data.return">
					<div class="bordered travellers"></div>
					<div class="date">
						{{ formatDate(data.return.departureDisplay) }}
					</div>
					<div class="bordered travellers"></div>

					<FlightCard
						v-if="data.return"
						class="flight-card return"
						:name="'Return'"
						:origin="data.return.origin"
						:destination="data.return.destination"
						:layoversTotal="data.return.layoversTotal"
						:layoverLocations="data.return.layoverLocations"
						:arrivalDisplay="data.return.arrivalDisplay"
						:departureDisplay="data.return.departureDisplay"
						:travelTime="data.return.travelTime"
						:flightsData="data.return.flightsArray"
						:booked="bookingStatus.code == 'booked'"
						:co2="data.return.totalCo2"
						:allFlightsFlown="data.return.allFlightsFlown"
						
						:loadOpen="loadOpen"
					/>
				</div>
			</div>

			<div v-else-if="type == 'tour'">
				<TourCard
					class="tour-card"
					:name="name"
					:images="data.images"
					:startLocation="data.startLocation.name"
					:endLocation="data.endLocation && data.endLocation.name != data.startLocation.name ? data.endLocation.name : false"
					:startDate="data.startDate"
					:finishDate="data.finishDate ? data.finishDate : false"
					:length="data.length"
					:mealsIncluded="data.mealsIncluded"
				/>
				<!-- {{ data }} -->
			</div>
		</div>
		<div v-else>
			<!-- Issue loading data - nothing to display -->
		</div>
		
		<!-- Other card types (e.g. hotel/tour) go here with v-if -->
	</div>
</template>

<script>
import helpers from '@/helpers/helpers.js';
import router from '@/router';
import FlightCard from "@/components/flights/FlightCard.vue";
import TourCard from "@/components/tours/TourCard.vue";

export default {
	components: {
		FlightCard,
		TourCard
	},
	props: {
		id: { // The Trip Item ID
		},
		tripID: { // The trip that this item belongs to
		},
		type: {
			default: "flight",
		},
		name: {
			type: String,
		},
		bookingStatus: {
			default: () => {
				return {
					"code": "not_booked",
					"text": "Not Booked",
					"bookingID": null
				}
			}
		},
		alert: {
			default: false,
		},
		notifications: {
			default: () => {
				return []
			}
		},
		price: {
			default: 0.00,
		},
		date: {
			default: "1659945966"
		},
		travellers: {
			default: () => {
				return []
			}
		},
		data: {
			default: () => {
				// Data is different depending on if it's a flight/hotel/tour etc
			}
		},

		showContinueButton: {
			default: true,
		},
		loadOpen: { // Show the collapsable elements as open by default (on load)
			default: false,
		}
	},
	data() {
		return {
			dynamicTravellers: [],
		}
	},
	methods: {
		formatDate: (date) => helpers.formatDate(date),
		formatDateWithoutYear: (date) => helpers.formatDateWithoutYear(date),
		formatTimeRange: (timeStamp) => helpers.formatTimeRange(timeStamp),
		formatTime: (timeStamp) => helpers.formatTime(timeStamp),
		prepareDynamicTravellers() {
			this.travellers.forEach(element => {
				let collapsable = false;
				if(element.data.dob || element.data.passportNumber || element.data.passportExpiry) {
					collapsable = true; // Only allow it to be collapsable if there's data to show
				}
				this.dynamicTravellers.push({...element, visible: this.loadOpen, collapsable: collapsable});
			});
		},
		getAlertImage() {
			if(this.bookingStatus.code == 'booked') {
				return require('../../assets/alerts/alert-green.png');
			}
			if(this.alert) {
				return require('../../assets/alerts/alert-' + this.alert.alert_code + '.png');
			}
		},
		getTypeImage() {
			return require('../../assets/trip/' + this.type + '-icon.png');
		},
		viewTripItem() {
			if(this.type == "flight") {
				router.push({
					name: 'Saved Flights Details',
					params: {
						trip_id: this.tripID,
						trip_item_id: this.id
					}
				})
			}
			if(this.type == "tour") {
				router.push({
					name: 'Saved Tour Details',
					params: {
						trip_id: this.tripID,
						trip_item_id: this.id
					}
				})
			}
		},
	},
	computed: {
		
	},
	created() {
		this.prepareDynamicTravellers(); // TODO this might be stopping the state updates from displaying? 
	}
}
</script>

<style scoped>
.trip_item {
}
.trip_item .bordered {
	border-left: 1px solid #C4C4C4;
	margin-left: 26px;
	padding-left: 15px;
}

.trip_item .status {
	position: relative;
	padding-bottom: 25px;
}
.trip_item .status .type-icon {
	width: 52px;
	position: absolute;
	left: -26px;
}
.trip_item .status .type-icon .alert-icon {
	position: absolute;
	right: -5px;
	top: 27px;
}
.trip_item .status .price {
	font-weight: 700;
	font-size: 20px;
	margin-left: 25px;
	line-height: 52px;
	min-height: 50px;
}
.trip_item .status .button {
	width: 157px;
	float: right;
}
@media screen and (max-width: 376px) {
	.trip_item .status .button {
		width: 100px;
	}
}
.trip_item .status .button.red {
	background-color: #FA5252;
}
.trip_item .date {
	font-weight: 700;
	font-size: 20px;
}


.trip_item .travellers {
	padding-bottom: 5px;
	padding-top: 10px;
}
.trip_item .travellers .traveller {
	margin-bottom: 10px;
	cursor: pointer;
}
.trip_item .travellers .main {
	position: relative;
	padding-left: 36px;
}
.trip_item .arrow {
	width: 24px;
	float: right;
	margin-top: 5px;
	margin-right: 5px;
	transform: rotate(180deg);
}
.trip_item .arrow.selected {
	transform: rotate(0deg);
}
.trip_item .travellers .profile_pic {
	width: 27px;
	height: 27px;
	display: inline-block;
	border-radius: 14px;
	background-color: #118AB2;
	vertical-align: middle;
	margin-right: 10px;
	position: absolute;
	left: 0;

	color: white;
	font-weight: 700;
	text-align: center;
	padding-top: 4px;
	box-sizing: border-box;
	font-size: 14px;
}
.trip_item .travellers .name {
	display: inline-block;
	vertical-align: middle;
	font-weight: 600;
	font-size: 14px;
	line-height: 28px;
}
.trip_item .travellers .info {
	padding-left: 36px;
	color: #50555C;
	font-size: 14px;
}

.trip_item .card {
	position: relative;
	border: 1px solid #C4C4C4;
	border-radius: 16px;
	background: #FCFCFE;

	max-width: 340px;
	box-sizing: border-box;
	width: 100%;
	max-width: 346px;
}
.trip_item .card .card-section {
	padding: 16px;
}
.trip_item .card .card-section.dotted {
	border-top: 1px dashed #C4C4C4;
}
.trip_item .card .top {
	margin-bottom: 10px;
}
.trip_item .card .top .icon {
	width: 11px;
	position: absolute;
	right: 30px;
	top: 22px;
}
.trip_item .card .top .layovers {
	position: absolute;
	right: 20px;
	top: 12px;

	font-size: 12px;
	color: #50555C;
}
.trip_item .card .range {
	width: 210px;
	display: flex;
	justify-content: space-between;
	align-items: flex-top;
}
.trip_item .card .range .start-time,
.trip_item .card .range .end-time {
	display: inline-block;
	vertical-align: middle;
}
.trip_item .card .range .date {
	font-weight: 400;
	font-size: 12px;
	color: #50555C;
}
.trip_item .card .range .start-time {
	padding-right: 10px;
}
.trip_item .card .range .end-time {
	text-align: right;
	padding-left: 10px;
}
.trip_item .card .range .location {
	font-size: 12px;
	color: #50555C;
	font-weight: 700;
}
.trip_item .card .range .journey {
	width: 100px;
	position: relative;
	height: 7px;
	display: inline-block;
	vertical-align: middle;
	margin-top: 7px;
}
.trip_item .card .range .journey .bar {
	width: 100%;
	height: 1px;
	background-color: #C4C4C4;
	position: relative;
	top: 3px;
}
.trip_item .card .range .journey .dot {
	border-radius: 4px;
	width: 7px;
	height: 7px;
	background-color: #C4C4C4;
	z-index: 10;
}
.trip_item .card .range .journey .start.dot {
	position: absolute;
	left: 0;
	top: 0;
}
.trip_item .card .range .journey .end.dot {
	position: absolute;
	right: 0;
	top: 0;
}

/* Modifications for the specific flights */
.trip_item .card .flight .range {
	width: 100%;
}
.trip_item .card .flight .range .journey {
	width: 100%;
	display: flex;
	align-items: center;
}
.trip_item .card .flight .range .journey .bar {
	top: 0;
}
.trip_item .card .flight .range .middle {
	width: auto;
	font-weight: 400;
	font-size: 12px;
	color: #50555C;
	padding: 0 5px;
	text-align: center;
	white-space: nowrap;
	max-width: 120px;
}
.trip_item .card .flight .range .middle .name {
	font-size: 10px;
	max-width: 120px;
	text-align: center;
}

.trip_item .card .layover-details {
	background-color: #F5FDFF;
	padding: 11px 16px;
}
.trip_item .card .layover-details .container {
	display: flex;
	flex-wrap: wrap;
}
.trip_item .card .layover-details .property {
	font-weight: 400;
	font-size: 12px;
	width: 50%;
	margin: 5px 0;
}
.trip_item .card .layover-details .property img {
	width: 16px;
	vertical-align: middle;
	margin-right: 5px;
}

.trip_item .card .layover_dots {
	display: flex;
	flex-flow: row wrap;
	justify-content: space-around;
	width: 75px;
	margin: 0 auto;
}
.trip_item .card .range .journey .layover.dot {
	background-color: black;
	position: absolute;
	top: 0;
}
.trip_item .card .bottom {
	margin-top: 20px;
	font-size: 12px;
	font-weight: 400;
	color: #50555C;
	width: 100%;
	position: relative;
}
.trip_item .card .bottom .section {
	display: inline-block;
	vertical-align: middle;
}
.trip_item .card .bottom .section .arrow {
	margin: 0;
}
.trip_item .card .bottom .section.length {
	margin-right: 24px;
}
.trip_item .card .bottom .section.expand {
	position: absolute;
	right: 0;
	top: -4px;
}
.trip_item .card .bottom .section.layovers .dot {
	border-radius: 4px;
	width: 7px;
	height: 7px;
	background-color: black;
	display: inline-block;
}

</style>