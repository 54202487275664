<template>
	<div class="card tours">
		<div class="card-section">
			<div class="image" :style="{'background-image': 'url(' + tourImage + ')'}">
			</div>
			<div class="name">{{ name }}</div>
			<span class="locations">
				<img class="location-icon" src="@/assets/tours/location-icon-blue.png">
				<span class="start">{{ startLocation }}</span>
				<span class="end" v-if="endLocation && endLocation != startLocation"> - {{ endLocation }}</span>
			</span>
			<!-- <pre>{{ data }}</pre> -->
			<div class="dates">
				<div class="start-date">Start date: {{ formatDate(startDate) }}</div>
				<div class="end-date" v-if="finishDate">End date: {{ formatDate(finishDate) }}</div>
			</div>
			<div class="length">
				<img src="@/assets/tours/day-icon.png" alt="Sunset icon" width="19px"/>
				<span v-if="length == 1">
					Day trip
				</span>
				<span v-else>
					{{ length }} days
				</span>
			</div>
			<div class="meals-title">
				Meals
			</div>
			<div class="meals">
				<div class="item" v-for="(value, key) in mealsIncluded" :key="key">
					<img class="icon" :src="mealIcon(key)" height="20px" alt="Meal Icon">
					<span class="amount">{{ value }}x </span>
					<span class="type">{{ key }}</span>
				</div>
			</div>

			<button class="button" v-if="linkFullDetails" @click="viewFullDetails()">View full details</button>
		</div>
	</div>
</template>

<script>
	import helpers from '@/helpers/helpers.js';
	export default {
		props: {
			name: {
				type: String,
				required: true
			},
			images: {
				type: Array,
				required: true
			},
			startLocation: {
				type: String,
				required: true
			},
			endLocation: {
				type: String,
				required: false
			},
			startDate: {
				type: String,
				required: true
			},
			finishDate: {
				type: String,
				required: null
			},
			length: {
				type: Number,
				required: true
			},
			mealsIncluded: {
				type: Object,
				required: true
			},
			linkFullDetails: {
				type: Object,
				required: false,
				default: null,
			},
		},
		data() {
			return {
				tourImage: null
			};
		},
		methods: {
			formatDate: (date) => helpers.formatDate(date),
			mealIcon: (meal) => helpers.mealIcon(meal),
			viewFullDetails() {
				this.$router.push(this.linkFullDetails);
			}
		},
		created() {
			let imagesPriority = [
				"BANNER_HIRES", // This is actually highest quality, but it's excessive for our purposes. Still better than regular 'BANNER' though.
				"BANNER_DESKTOP",
				"BANNER", // 
				"LARGE_SQUARE" // Highest priority
			];
			if(this.images && this.images.length > 0) {
				// Order images based on priority
				let orderedImages = this.images.sort((a, b) => {
					// Order based on imagesPriority
					let aIndex = imagesPriority.indexOf(a.type);
					let bIndex = imagesPriority.indexOf(b.type);
					if(aIndex > bIndex) {
						return -1;
					}
					if(aIndex < bIndex) {
						return 1;
					}
					return -1;
				});
				// Set the main image href to the highest priority image
				this.tourImage = orderedImages[0].image_href;
			}
			
		}
	}
</script>

<style scoped>
	.tours {
		position: relative;
		border: 1px solid #C4C4C4;
		border-radius: 16px;
		background: #FCFCFE;

		font-size: 18px;
		font-weight: 500;
		color: black;

		max-width: 360px;
		box-sizing: border-box;
	}
	.tours .card-section {
		padding: 16px;
	}
	.tours .name {
		font-weight: 600;
		font-size: 16px;
		line-height: 19px;
		color: #000000;
		margin-bottom: 28px;
	}
	.tours .image {
		float: right;
		width: 100px;
		height: 100px;
		border-radius: 10px;
		background-size: cover;
		background-position: center;
	}

	.tours .locations {
		font-weight: 400;
		font-size: 10px;
		line-height: 12px;

		color: #000000;
	}
	.tours .locations .location-icon {
		width:  8px;
		margin-right: 5px;
		vertical-align: middle;
	}
	.tours .dates {
		font-weight: 400;
		font-size: 10px;
		line-height: 12px;
		color: #000000;
		margin-top: 10px;
	}
	.tours .length {
		font-weight: 700;
		font-size: 12px;
		line-height: 15px;
		color: #212121;
		margin-top: 10px;
	}
	.tours .meals-title {
		font-weight: 600;
		font-size: 12px;
		line-height: 15px;
		color: #000000;
		margin-top: 20px;
		margin-bottom: 8px;
	}
	.tours .meals {
		display: flex;
		flex-wrap: wrap;
	}
	.tours .meals .item {
		width: 50%;
		font-weight: 400;
		font-size: 10px;
		line-height: 12px;
		color: #000000;
		text-transform: capitalize;
		margin-bottom: 8px;
	}
	.tours .meals .item .icon {
		margin-right: 5px;
	}
	.tours .meals .item * {
		vertical-align: middle;
	}

	.tours .button {
		display: block;
		margin: 0 auto;
		margin-top: 10px;
		margin-bottom: 10px;
	}

</style>